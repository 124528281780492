









import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmModal from "../../commons/ConfirmModal.vue";
import {
  DeleteOperationTemplateMutation,
  DeleteOperationTemplateMutationVariables,
  OperationTemplate,
} from "../../../types/gqlGeneratedPrivate";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { apiClient } from "../../../utils/apiClient";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { Action } from "vuex-class";
import { TableRefreshPayload } from "../../../types";

@Component({
  components: { ConfirmModal },
})
export default class OperationTemplateDeleteModal extends Vue {
  @Prop({ type: String, required: true })
  private operationName!: string;

  EventBus = EventBus;
  isSending = false;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.OperationTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  async onConfirm(payload: OperationTemplate) {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      DeleteOperationTemplateMutation,
      DeleteOperationTemplateMutationVariables
    >({
      ...api.operationTemplates.deleteOperationTemplate,
      variables: {
        id: payload.id,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("DELETE_OPERATION_TEMPLATE", "Smazáno");
      this.refreshTable({
        namespace: TablesNamespaces.OperationTemplatesTable,
      });
    }
    this.isSending = false;
  }
}
