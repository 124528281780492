





















import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { TableOptions } from "../types";
import {
  OperationTemplate,
  OperationTemplatesFilterEnum,
} from "../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../store/modules/tables";
import Modal from "../components/commons/Modal.vue";
import { EventBus } from "../enums/EventBus";
import { eventBus } from "../utils/eventBus";
import OperationTemplateDeleteModal from "../components/app/operationTemplates/OperationTemplateDeleteModal.vue";
import OperationTemplateCreateModal from "../components/app/operationTemplates/OperationTemplateCreateModal.vue";
import OperationTemplateUpdateModal from "../components/app/operationTemplates/OperationTemplateUpdateModal.vue";

@Component({
  components: {
    AppLayout,
    Button,
    Modal,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    OperationTemplateDeleteModal,
    OperationTemplateCreateModal,
    OperationTemplateUpdateModal,
  },
})
export default class OperationTemplatesPage extends Vue {
  private itemName = "";

  tableOptions: TableOptions<OperationTemplate, OperationTemplatesFilterEnum> =
    {
      defaultSortBy: ["name"],
      headers: [
        {
          text: "Název",
          sortable: true,
          align: "start",
          value: "name",
        },
        {
          text: "min time 1",
          sortable: true,
          align: "start",
          value: "minimumTime",
        },
        {
          text: "comfort time 1",
          sortable: true,
          align: "start",
          value: "comfortTime",
        },
        { text: "value 1", sortable: true, align: "start", value: "value" },
        {
          text: "min time 2",
          sortable: true,
          align: "start",
          value: "minimumTimeTwo",
        },
        {
          text: "comfort time 2",
          sortable: true,
          align: "start",
          value: "comfortTimeTwo",
        },
        { text: "value 2", sortable: true, align: "start", value: "valueTwo" },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      namespace: TablesNamespaces.OperationTemplatesTable,
    };

  deleteItem(operationTemplate: OperationTemplate): void {
    this.itemName = operationTemplate.name;

    eventBus.$emit(EventBus.OperationsDeleteModal, operationTemplate);
  }

  addItem(): void {
    eventBus.$emit(EventBus.OperationsCreateModal);
  }

  updateItem(operationTemplate: OperationTemplate): void {
    eventBus.$emit(EventBus.OperationsUpdateModal, operationTemplate);
  }
}
