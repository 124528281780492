



















































import { Component, Prop, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { OperationTemplateFormFragment } from "../../../types/gqlGeneratedPrivate";

@Component({
  components: {
    Modal,
    ValidationObserver,
    Button,
    TextField,
  },
})
export default class OperationTemplateForm extends Vue {
  @Prop({ type: Object, required: true })
  value!: OperationTemplateFormFragment;

  async validate(): Promise<boolean> {
    return (this.$refs.observer as any).validate();
  }

  reset(): void {
    (this.$refs.observer as any).reset();
  }
}
